import { OperationVariables, QueryHookOptions, QueryResult } from '@apollo/client';
import AddIcon from '@mui/icons-material/Add';
import { Box, createTheme, ThemeProvider, Typography } from '@mui/material';
import _ from 'lodash';
import MUIDataTable, { MUIDataTableColumnOptions, MUIDataTableOptions, MUIDataTableState, MUISortOptions } from 'mui-datatables';
import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useWindowSize } from '../lib/hooks';
import { SEARCH_DEBOUNCE, SEARCH_MINCHAR } from '../util';
import { FABBottomRight } from '../widgets';

interface MUIDataTableColumnOptionsWithLabel extends MUIDataTableColumnOptions {
  label?: string;
}
export interface ColumnAttributes {
  [name: string]: MUIDataTableColumnOptionsWithLabel;
}

export interface IColumnLabel {
  key: string;
  label: string;
}

export interface DataTableProps<WhereInput, OrderByInput, QueryResponseType, RowType> {
  useQuery: <TVariables = OperationVariables>(
    options: QueryHookOptions<QueryResponseType, TVariables> | undefined
  ) => QueryResult<QueryResponseType, TVariables>;
  initialQuery: QueryDefinition<WhereInput, OrderByInput>;
  searchQuery?: (term: string) => WhereInput;
  onColumnSortChange?: (col: string, dir: string) => void;
  transformResponseToRows: (data: QueryResponseType | undefined) => RowType[];
  onRowClick?: (row: RowType, pageNumber: number, rowsPerPage: number) => void;
  onRowSelect?: (rows: RowType[]) => void;
  columnAttributes?: ColumnAttributes;
  tableAttributes?: MUIDataTableOptions;
  title?: string;
  onAdd?: () => void;
  className?: string;
  emptyMessage?: string;
  filterType?: string;
  testLabels?: {
    tableName?: string;
    addButton?: string;
  };
  components?: any;
  search?: boolean;
  searchTerm?: string;
  searchColumns?: string[]; // for searching ui data
  pageNumber?: any;
}

export interface QueryDefinition<WhereInput, OrderByInput> {
  where?: WhereInput | null;
  orderBy?: OrderByInput[] | null;
  skip?: number | null;
  after?: string | null;
  before?: string | null;
  first?: number | null;
  last?: number | null;
  take?: number | null;
}

export const dtTheme = createTheme({
  components: {
    MuiIconButton: {
      styleOverrides: {
        root: {
          '& svg': {
            width: '1.4em',
            height: '1.4em',
          },
        },
      },
    },

    MuiTableHead: {
      styleOverrides: {
        root: {
          position: 'relative',
          zIndex: 1001,
        },
      },
    },
    MUIDataTableBodyRow: {
      styleOverrides: {
        root: {
          cursor: 'pointer',
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        head: {
          backgroundColor: `#eee !important`,
          fontSize: '18px',
          padding: '0 16px',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          fontSize: '18px',
        },
      },
    },
    MUIDataTableSelectCell: {
      styleOverrides: {
        fixedLeft: {
          backgroundColor: '#fff',
          zIndex: 1000,
        },
      },
    },
  },
});

const DEBUG = false;

export function DataTable<WhereInput, OrderByInput, QueryResponseType, RowType>(
  props: DataTableProps<WhereInput, OrderByInput, QueryResponseType, RowType>
) {
  const [query, setQuery] = useState<QueryDefinition<WhereInput, OrderByInput>>(props.initialQuery);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  useEffect(() => {
    if (props.searchTerm) {
      debouncedSearch(props.searchTerm, null, null);
    }
  }, [props.searchTerm]);

  const { loading, error, data, refetch } = props.useQuery({
    variables: query,
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    setQuery(props.initialQuery);
    refetch(props.initialQuery)
      .then((result) => {
        if (result.error) {
          setErrorMessage(result.error.message);
        } else {
          setErrorMessage(null);
          handleData(result.data);
        }
      })
      .catch((error) => {
        setErrorMessage(error.message);
      });
  }, [props.initialQuery]);

  const [columnAttributes, setColumnAttributes] = useState<ColumnAttributes>(
    Object.assign({}, { __typename: { display: 'excluded' } }, props.columnAttributes)
  );
  const [pageNumber, setPageNumber] = useState<number>(props?.pageNumber || 0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);
  const [sortOrder, setSortOrder] = useState<MUISortOptions | undefined>();
  const [searchTerm, setSearchTerm] = useState<string>(props.searchTerm || '');
  const [tableData, setTableData] = useState<any[]>([]);
  const [columns, setColumns] = useState<any>([]);
  const refData = useRef<any[]>([]);
  const refCols = useRef<number[]>([]);
  const tableRef = useRef<HTMLDivElement>();
  const wndoSize = useWindowSize();
  const [tableHeight, setTableHeight] = useState('0');

  useEffect(() => {
    const { orderBy } = props.initialQuery;
    if (orderBy && orderBy[0]) {
      const entries = Object.entries(orderBy[0]);
      entries.forEach((entry) => {
        const name = entry[0];
        const direction = entry[1];
        const order = { name, direction };
        setSortOrder(order);
      });
    }
  }, []);

  useEffect(() => {
    if (tableRef.current) {
      const top = tableRef.current?.offsetTop + 120;
      const h = `${wndoSize.height - top}px`;
      setTableHeight(h);
    }
  }, [wndoSize.height, tableRef.current]);

  const handleData = (data: any) => {
    if (data) {
      const respRows = props.transformResponseToRows(data);
      DEBUG && console.debug('DataTable', 'render', { respRows, props, query });
      const cols = colsFromData(respRows);
      setColumns(cols);
      if (props.searchColumns?.length) {
        const indices: number[] = [];
        for (let i = 0; i < cols.length; i++) {
          if (props.searchColumns.indexOf(cols[i].name) >= 0) {
            indices.push(i);
          }
        }
        refCols.current = indices;
      }
      const fetched = objArrayToArrayArray(respRows);
      refData.current = fetched;
      setTableData([...fetched]);
    }
  };

  useEffect(() => {
    handleData(data);
  }, [data]);

  const debouncedSearch = useCallback(
    _.debounce((val: string | null, page: number | null, rowsPerPage: number | null) => {
      if (refCols.current.length) {
        if (val?.length) {
          const found = refData.current.filter((d) => {
            val = val?.toLowerCase() || '';
            let ok = false;
            for (let col of refCols.current) {
              if (d[col].toLowerCase().indexOf(val) >= 0) {
                ok = true;
              }
            }
            return ok;
          });
          setTableData([...found]);
        } else {
          setTableData(refData.current);
        }
      } else {
        setSearchTerm(val || '');
        if (props.searchQuery) {
          if (val) {
            (val.length >= SEARCH_MINCHAR || val.length === 0) &&
              setQuery({ where: props.searchQuery(val), skip: page, take: rowsPerPage });
          } else {
            setQuery({ where: props.searchQuery(''), skip: page, take: rowsPerPage });
          }
        }
      }
    }, SEARCH_DEBOUNCE),
    []
  );

  if (loading) {
    return <Typography variant="body1">Loading</Typography>;
  }
  if (error) {
    return <div>{error.message}</div>;
  }
  if (errorMessage) {
    return <div>{errorMessage}</div>;
  }

  function rowClicked(colummns: any[], rowData: any, pageNumber: number, rowsPerPage: number) {
    if (props.onRowClick) {
      props.onRowClick(arrayToObject(colummns, rowData), pageNumber, rowsPerPage);
    }
  }
  function rowSelected(curRowSelected: any, allRowsSelected: any, colummns: any[]) {
    const selectedRowData = tableData.filter((data: any, index: number) => {
      return allRowsSelected.some((rowData: any) => {
        return index === rowData.index;
      });
    });
    if (props?.onRowSelect) {
      props?.onRowSelect(multiArrayToObject(colummns, selectedRowData));
    }
  }

  function sortColumn(name: string, dir: string) {
    if (props.onColumnSortChange) {
      props.onColumnSortChange(name, dir);
    }
  }

  function colsFromData(arr: any) {
    const columns: any[] = [];
    if (!arr || arr.length === 0) {
      return [];
    }
    const firstObj = arr[0];
    Object.keys(firstObj).forEach((key) => {
      columns.push({
        name: key,
        label: key,
        options: Object.assign(
          {},
          {
            filter: true,
            sort: true,
            download: true,
          },
          columnAttributes[key]
        ),
      });
    });
    return columns;
  }

  function objArrayToArrayArray(arr: any) {
    if (!arr || arr.length === 0) {
      return [];
    }
    const firstObj = arr[0];
    const keys = Object.keys(firstObj);
    return arr.map((row: any) => {
      const retVal: any[] = [];
      keys.forEach((key) => {
        retVal.push(row[key]);
      });
      return retVal;
    });
  }

  function arrayToObject(columns: any[], arr: any[]): RowType {
    const retVal: any = {};
    columns.forEach((col, index) => {
      retVal[col.name] = arr[index];
    });
    return retVal as RowType;
  }

  function multiArrayToObject(columns: any[], arr: any[]): RowType[] {
    const retVal: any = [];
    arr.forEach((row, index) => {
      const obj: any = {};
      columns.forEach((col, colIndex) => {
        obj[col.name] = row[colIndex];
      });
      retVal.push(obj);
    });
    return retVal as RowType[];
  }

  const options: MUIDataTableOptions = Object.assign(
    {},
    {
      onColumnSortChange: (col: any, dir: any) => sortColumn(col, dir),
      filterType: props?.filterType ? props?.filterType : 'textField',
      selectableRows: props?.filterType && props?.filterType === 'checkbox' ? 'multiple' : 'none',
      onRowClick: (rowData: any) => rowClicked(columns, rowData, pageNumber, rowsPerPage),
      onRowSelectionChange: (curRowSelected: any, allRowsSelected: any) => rowSelected(curRowSelected, allRowsSelected, columns),
      download: true,
      serverSide: true,
      resizableColumns: true,
      fixedHeader: true,
      fixedSelectColumn: true,
      rowsPerPage,
      sortOrder,
      page: pageNumber,
      responsive: 'standard',
      search: props?.search || true,
      searchOpen: !!props.searchTerm,
      searchText: searchTerm,
      textLabels: {
        body: {
          noMatch: props.emptyMessage,
        },
      },
      rowsPerPageOptions: [10, 25, 100],
      onChangeRowsPerPage: (count: number) => {
        setRowsPerPage(count);
        setQuery({ ...query, take: count });
      },
      onTableChange: (action: string, tableState: MUIDataTableState) => {
        DEBUG && console.debug('DataTable', 'onTableChange', { action, tableState });
        const page = tableState.page;
        const rowsPerPage = tableState.rowsPerPage;
        switch (action) {
          case 'sort':
            // sort
            // current order
            const curOrderObj = query.orderBy && query.orderBy[0];
            const newOrderObj: any = {};
            const newDirection = tableState.sortOrder.direction; // === 'asc' ? 'desc' : 'asc';
            setSortOrder(tableState.sortOrder);
            newOrderObj[tableState.sortOrder.name] = newDirection;
            DEBUG && console.debug('DataTable', 'onTableChange', { curOrderObj, newOrderObj, query });
            setQuery({ where: query.where, orderBy: [newOrderObj as any] });
            // tableState.sortOrder.direction = newDirection;
            break;
          case 'propsUpdate':
            break;
          case 'changeRowsPerPage':
            break;
          case 'search':
            const term = tableState.searchText;
            debouncedSearch(term, page, rowsPerPage);
            break;
          case 'onSearchOpen':
            break;
          case 'onSearchClose':
            debouncedSearch(null, page, rowsPerPage);
            break;
          case 'onFilterDialogOpen':
            break;
          case 'rowSelectionChange':
            break;
          case 'changePage':
            setQuery(
              Object.assign({}, query, {
                skip: page * rowsPerPage,
                take: rowsPerPage,
              })
            );

            setPageNumber(page);
            setRowsPerPage(rowsPerPage);
            break;
          default:
            DEBUG && console.debug('DataTable', 'onTableChange - if this is an infinite loop, add a case', { action, tableState });
            break;
        }
      },
      count: (pageNumber + 1) * rowsPerPage + 1, // Assume there's more
    },
    props.tableAttributes
  );

  return (
    <Box className={props.className} data-test={props.testLabels?.tableName} ref={tableRef}>
      <ThemeProvider theme={dtTheme}>
        <MUIDataTable options={options} columns={columns} data={tableData} title={props.title} components={props.components} />
      </ThemeProvider>
      {props.onAdd ? (
        <FABBottomRight onClick={props.onAdd} data-test={props.testLabels?.addButton}>
          <AddIcon />
        </FABBottomRight>
      ) : (
        ''
      )}
    </Box>
  );
}
